<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate ? '修改用户' : '添加用户'"
    :visible="visible"
    width="600px"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="82px">
      <el-row :gutter="15">
        <el-col :sm="12">
          <el-form-item label="用户名:" prop="username">
            <el-input
              v-model="form.username"
              placeholder="请输入用户名"
              clearable
              :disabled="isUpdate"
            />
          </el-form-item>
          <el-form-item label="角色:" prop="roles">
            <el-select
              v-model="form.roles"
              placeholder="请选择角色"
              class="ele-block"
              clearable
              multiple
            >
              <el-option
                v-for="item in roleList"
                :key="item.role_id"
                :label="item.role_name"
                :value="item.role_id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item label="手机号:" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号"
              clearable
            />
          </el-form-item>
          <el-form-item label="登录密码:" :prop="!isUpdate ? 'password' : ''">
            <el-input
              v-model="form.password"
              :placeholder="!isUpdate ? '请输入密码' : '不修改请留空'"
              show-password
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!--选项-->
      <el-form-item
        label="头像"
        :rules="{
          required: true,
          message: '请上传图片 ',
          trigger: 'blur',
        }"
      >
        <!-- 图片 -->
        <el-col :span="6">
          <el-upload
            class="avatar-uploader"
            :action="
              $http.defaults.baseURL + 'upload/image/module_upload/image'
            "
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >  
            <img v-if="form.avatar" :src="$http.defaults.baseIMGURL + form.avatar" class="avatar" />
            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="updateVisible(false)">取消 </el-button>
      <el-button type="primary" @click="save" :loading="loading"
        >保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from "ele-admin/packages/validate";

export default {
  name: "UserEdit",
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
  },
  data() { 
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        username: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "blur" }],
        password: [{ required: true, message: "请输入登录密码" }],
        phone: [
          {
            pattern: validate.phone,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [], 
      avatarBaseUrl: ''
    };
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data, {
          roles: this.data.roles.map((d) => d.role_id),
        });
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    },
  },
  mounted() {
    this.queryRoles(); // 查询角色列表
  },
  methods: {
    /* 保存编辑 */
    save() { 
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? "put" : "post"](
            this.isUpdate ? "/Admin/users/" + this.form.id : "/Admin/users",
            this.form
          )
            .then((res) => {
              this.loading = false;
              if (res.data.code === 200) {
                this.$message({ type: "success", message: res.data.msg });
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit("done");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message.error(e.message);
            });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
    /* 查询角色列表 */
    queryRoles() {
      this.$http
        .get("/Admin/role")
        .then((res) => {
          if (res.data.code === 200) {
            this.roleList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    // 上传图片成功
    handleAvatarSuccess(res, file) { 
     // 上传后台
    this.form.avatar = res.data.path 
     this.$forceUpdate()
    },
    // 上传之前的回调
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style scoped>
.avatar-uploader i {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader i:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  display: block;
}
</style>
